import { render, staticRenderFns } from "./MobileProfileSidebar.vue?vue&type=template&id=182096f1&scoped=true"
import script from "./MobileProfileSidebar.vue?vue&type=script&setup=true&lang=ts"
export * from "./MobileProfileSidebar.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./MobileProfileSidebar.vue?vue&type=style&index=0&id=182096f1&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "182096f1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComposedMenuProfileContent: require('/root/build_gs/core/components/composed/menu/ProfileContent.vue').default,BaseSidebarSliding: require('/root/build_gs/core/components/base/sidebar/Sliding.vue').default,BaseOverlaySimple: require('/root/build_gs/core/components/base/overlay/Simple.vue').default})
