var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('aside',{staticClass:"position-relative d-flex flex-row align-items-stretch",on:{"mouseleave":_setup.delayedCloseSubMenu}},[_c('BaseOverlaySimple',{staticClass:"sidebar-overlay position-absolute top-0 left-0 z-1",attrs:{"enabled":!!_setup.openedSubMenuObject?.children?.length},nativeOn:{"mouseenter":function($event){return _setup.delayedCloseSubMenu.apply(null, arguments)}}}),_vm._v(" "),_c('div',{staticClass:"aside-content d-flex flex-column"},[_c('BaseSidebarSliding',{staticClass:"secondary-sidebar position-absolute top-0",attrs:{"open":!!_setup.openedSubMenuObject?.children?.length},nativeOn:{"mouseenter":function($event){return _setup.delayedOpenSubMenu()},"mouseleave":function($event){return _setup.delayedCloseSubMenu.apply(null, arguments)}}},[_vm._l((_setup.openedSubMenuObject?.children),function(item){return [(!item.invisible)?_c(item.component || 'ComposedMenuItemSecondary',{tag:"component",attrs:{"to":item.to,"title":_vm.$t(item.title),"badgeProps":item.badgeProps ? {
                        size: item.badgeProps.size,
                        type: item.badgeProps.type,
                        text: item.badgeProps.text ? _vm.$t(item.badgeProps.text) : null,
                    } : null,"disabled":item.disabled || false,"active":_setup.activeItemPath.includes(item)}}):_vm._e()]})],2),_vm._v(" "),_c('ComposedSelectMetaverse',{staticClass:"metaverse-switch"}),_vm._v(" "),_c('section',{staticClass:"sidebar-content flex-fill"},[_c('ul',[_vm._l((_setup.sidebarItems),function(item){return [(!item.invisible)?_c(item.component || 'ComposedMenuItemCollapsible',{tag:"component",attrs:{"to":item.to,"title":_vm.$t(item.title),"value":_setup.openedSubMenuObject === item,"active":_setup.activeItemPath.includes(item),"badgeProps":item.badgeProps ? {
                            size: item.badgeProps.size,
                            type: item.badgeProps.type,
                            text: item.badgeProps.text ? _vm.$t(item.badgeProps.text) : null,
                        } : null,"disabled":item.disabled || false,"prefixIcon":item.prefixIcon || null,"prefixIconActive":item.prefixIconActive || null,"suffixIcon":item.suffixIcon || null,"suffixIconActive":item.suffixIconActive || null},nativeOn:{"mouseenter":function($event){!item?.disabled && item?.children ? _setup.delayedOpenSubMenu(item) : _setup.delayedCloseSubMenu()}}}):_vm._e()]})],2)]),_vm._v(" "),(_setup.user && _setup.user.walletAddress && _setup.user.isReferralLinkActive && !_setup.user.isAutoshipFree)?_c('section',{staticClass:"autoship-status-section"},[_c('ComposedCountdownAutoship',{attrs:{"user":_setup.user}})],1):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }